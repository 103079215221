<template>
  <div class="error-robot">
    <div class="error-robot__image-wrapper">
      <div class="error-robot__inner-wrapper" :style="imageWrapperDynamicStyle">
        <a-image
          v-if="icon"
          :aspect-ratio="aspectRatios.robots"
          :src="icon"
          :alt="ROBOT_ALT_TEXT"
          class="error-robot__image"
        />
      </div>
    </div>
    <h1 class="font-sans normal">
      <p class="error-robot__title">Error</p>
      <p class="error-robot__code">{{ statusCode }}</p>
      <p v-if="title" class="error-robot__message">
        {{ title }}
      </p>
    </h1>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

import { propValidator, PROP_TYPES } from '@/utils/validators'
import { COOKIES_PROP_NAME } from 'enums/robot'
import { getLinkToFlBucketFile } from '~/plugins/helper'
import { aspectRatios } from 'enums/aspectRatios'
import mixins from '@/utils/mixins'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'

const DEFAULT_TITLE = 'page not found'
const ROBOT_ALT_TEXT = 'Robot serving 404 page'

const ROBOT_IMAGE_NAME = {
  ROBOT_1: 'robot-1.svg',
  ROBOT_2: 'robot-2.svg',
  ROBOT_3: 'robot-3.svg',
  ROBOT_4: 'robot-4.svg',
  ROBOT_5: 'robot-5.svg'
}

const ROBOT_IMAGE_NAME_VALUES = Object.values(ROBOT_IMAGE_NAME)

function getImageByIndex(index) {
  if (index > ROBOT_IMAGE_NAME_VALUES.length) {
    return getLinkToFlBucketFile(ROBOT_IMAGE_NAME_VALUES[0])
  }

  return getLinkToFlBucketFile(ROBOT_IMAGE_NAME_VALUES[index])
}

function getNextImageIndex(index) {
  const next = index + 1
  return next >= ROBOT_IMAGE_NAME_VALUES.length ? 0 : next
}

export default {
  name: 'ARobot',
  mixins: [mixins.oneTrust],
  props: {
    statusCode: propValidator([PROP_TYPES.NUMBER]),
    title: propValidator([PROP_TYPES.STRING], false, DEFAULT_TITLE)
  },
  data() {
    return {
      icon: null,
      aspectRatios,
      ROBOT_ALT_TEXT
    }
  },
  computed: {
    imageIndex() {
      if (
        !this.$_oneTrust_isConsentGivenForEntity(
          COOKIE_ENTITY_TYPE.ROBOTS_ROTATION
        )
      ) {
        return 0
      }

      return +Cookies.get(COOKIES_PROP_NAME) || 0
    },
    imageWrapperDynamicStyle() {
      return this.$helper.generateAspectRatioStyle(aspectRatios.robots)
    }
  },
  mounted() {
    this.icon = getImageByIndex(this.imageIndex)

    if (
      this.$_oneTrust_isConsentGivenForEntity(
        COOKIE_ENTITY_TYPE.ROBOTS_ROTATION
      )
    ) {
      Cookies.set(COOKIES_PROP_NAME, getNextImageIndex(this.imageIndex))
    }
  }
}
</script>

<style lang="scss" scoped>
.error-robot {
  font-family: $font-sans;
  text-align: center;

  @include mobile {
    margin: 0 auto;
  }

  &__image-wrapper {
    max-height: 470px;
    max-width: 400px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
  }

  &__title {
    font-family: inherit;
    font-size: 27px;
    margin-top: 30px;

    @include mobile {
      margin-top: 20px;
      font-size: 18px;
    }
  }

  &__code {
    font-family: inherit;
    font-size: 45px;
    font-weight: bold;
    color: $c--main;
    line-height: 61px;

    @include mobile {
      font-size: 28px;
      line-height: 38px;
    }
  }

  &__message {
    font-family: inherit;
    font-size: 27px;
    color: $c--main;

    @include mobile {
      font-size: 18px;
    }
  }
}
</style>
